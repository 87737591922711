import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export default function StandardImageList() {
  const [state, setState] = useState();
  function getCols(width) {
    if (width > 900) setState({ cols: 8 });
    else setState({ cols: 4 });
  }
  useEffect(() => {
    getCols(window.innerWidth);
    function handleResize() {
      getCols(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, []);
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}`,
      srcSet: `${image}`,
    };
  }
  return (
    <div style={{ borderTop: "3px solid #b21e23" }}>
      {state && (
        <ImageList cols={state.cols} style={{ margin: "5px 0px" }}>
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  );
}

const itemData = [
  {
    img: process.env.PUBLIC_URL + "/img/slide/1.jpg",
    title: "Breakfast",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/2.jpg",
    title: "Burger",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/3.jpg",
    title: "Camera",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/4.jpg",
    title: "Coffee",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/5.jpg",
    title: "Hats",
    cols: 4,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/6.jpg",
    title: "Honey",
    author: "@arwinneil",
    cols: 4,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/7.jpg",
    title: "Basketball",
    cols: 4,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/8.jpg",
    title: "Fern",
    cols: 4,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/9.jpg",
    title: "Mushrooms",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/10.jpg",
    title: "Tomato basil",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/11.jpg",
    title: "Sea star",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/12.jpg",
    title: "Bike",
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/img/slide/13.jpg",
    title: "Bike",
    cols: 4,
  },
];
