import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Footer from "./components/Footer";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { config } from "./const";

export default function Ingredient() {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [state, setState] = useState();
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      let item = await axios.get(
        config.endpoint.ttmcc + "/index.php?collection=ingredients&id=" + id
      );
      setItem(item.data);

      let navigate = await axios.post(config.endpoint.ttmcc + "/index.php", {
        sql: "select _id from ingredients",
        limit: 0,
      });
      navigate = navigate.data.result;
      let i = navigate.findIndex((j) => parseInt(j._id) === parseInt(id));
      let state = {
        prev: -1,
        next: -1,
      };
      if (i > 0) {
        state.prev = navigate[i - 1]._id;
      } else {
        state.prev = navigate[navigate.length - 1]._id;
      }
      if (i < navigate.length - 1) {
        state.next = navigate[i + 1]._id;
      } else {
        state.next = navigate[0]._id;
      }
      setState(state);
    })();
  }, [id]);
  return (
    <div>
      {item && state && (
        <>
          <div
            style={{
              background: "#b21e23",
              height: "450px",
              paddingTop: "20px",
            }}
          >
            <div style={{ height: "180px" }}>
              <div style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#fff",
                    fontSize: "3.5rem",
                    marginBottom: "0px",
                  }}
                  className="bold"
                >
                  {item.title_alt || "Ingredient Title"}
                </h1>
              </div>
              <div style={{ textAlign: "center" }}>
                <h2
                  style={{ color: "#fff", fontSize: "3rem", marginTop: "0px" }}
                  className="bold"
                >
                  {item.title}
                </h2>
              </div>
            </div>
            <div>
              <Grid container justifyContent="center">
                <Grid
                  item
                  style={{
                    width: "80px",
                    paddingTop: "130px",
                  }}
                  align="left"
                >
                  {state.prev !== -1 && (
                    <IconButton
                      style={{ color: "#fff", padding: "3px 3px" }}
                      onClick={() =>
                        navigate(
                          process.env.PUBLIC_URL + "/ingredient/" + state.prev
                        )
                      }
                    >
                      <Icon style={{ fontSize: "5rem" }}>arrow_left</Icon>
                    </IconButton>
                  )}
                </Grid>
                <Grid item align="center">
                  <div
                    id="ingredientImg"
                    style={{
                      marginBottom: "-100px",
                      width: "400px",
                      height: "400px",
                      maxHeight: "50vw",
                      maxWidth: "50vw",
                      margin: "auto",
                      border: "2px solid #fff",
                      backgroundImage:
                        "url(" +
                        process.env.PUBLIC_URL +
                        "/img/herbs/" +
                        item.filename +
                        ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                </Grid>
                <Grid
                  item
                  style={{
                    width: "80px",
                    paddingTop: "130px",
                  }}
                  align="right"
                >
                  {state.next !== -1 && (
                    <IconButton
                      style={{ color: "#fff", padding: "3px 3px" }}
                      onClick={() =>
                        navigate(
                          process.env.PUBLIC_URL + "/ingredient/" + state.next
                        )
                      }
                    >
                      <Icon style={{ fontSize: "5rem" }}>arrow_right</Icon>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div style={{ height: "25vw", maxHeight: "200px" }}></div>
          <div style={{ paddingBottom: "50px" }}>
            <Container>
              <p style={{ fontSize: "1.2rem", textAlign: "justify" }}>
                {item.description}
              </p>
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Icon>home</Icon>}
                  onClick={() => navigate(process.env.PUBLIC_URL + "/")}
                >
                  Back to home
                </Button>
              </div>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
