import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

export default function Footer() {
  const styles = {
    socialContainer: {
      paddingTop: "6px",
      paddingLeft: "6px",
      background: "#767677",
      color: "#24292f",
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      marginRight: "10px",
    },
  };
  return (
    <div>
      <div
        style={{
          height: "55px",
          background:
            "url(" + process.env.PUBLIC_URL + "/img/logo-whezhou-04.png)",
          backgroundSize: "contain",
        }}
      />
      <div style={{ background: "#191718" }}>
        <Container
          maxWidth="xl"
          style={{
            paddingTop: "80px",
            paddingBottom: "100px",
            color: "#767677",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3} align="left">
              <h3 style={{ fontSize: "1.2rem" }} className="bold">
                Location
              </h3>
              <div style={{ fontSize: ".9rem" }}>
                Chiang Mai University Library 239 Huay Kaew Road,
                <div>Muang District, Chiang Mai 50200, THAILAND</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} align="left">
              <h3 style={{ fontSize: "1.2rem" }} className="bold">
                Hours
              </h3>
              <div style={{ fontSize: ".9rem" }}>
                <ul>
                  <li style={{ marginBottom: "10px" }}>
                    Semester<div>Mon – Fri 8:00 am – 9:00 pm</div>
                    <div>Sat – Sun 10:00 – 6:00 pm</div>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Semester Break<div>Mon – Fri 8:30 am – 4:30 pm</div>
                    <div>Sat – Sun Closed</div>
                  </li>
                  <li>Public Holidays : Closed</li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} align="left">
              <h3 style={{ fontSize: "1.2rem" }} className="bold">
                Contact Us
              </h3>
              <div style={{ fontSize: ".9rem" }}>
                <div>References and Research Services : 0-5394-4531</div>
                <div>Circulation Service : 0-5394-4513</div>
                <div>Public relations : 0-5394-4567</div>
                <div>Office : 0-5394-4506</div>
                <div>FAX : 0-5394-4566</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} align="left">
              <h3 style={{ fontSize: "1.2rem" }} className="bold">
                Social Network
              </h3>
              <div style={{ fontSize: ".9rem" }}>
                <div style={{ display: "table-cell" }}>
                  <div style={styles.socialContainer}>
                    <Icon>facebook</Icon>
                  </div>
                </div>
                <div style={{ display: "table-cell", paddingTop: "0px" }}>
                  <div style={styles.socialContainer}>
                    <Icon>email</Icon>
                  </div>
                </div>
                <div style={{ display: "table-cell", paddingTop: "0px" }}>
                  <div
                    style={{
                      paddingTop: "2px",
                      paddingLeft: "2px",
                      background: "#767677",
                      color: "#24292f",
                      height: "33px",
                      width: "33px",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "10px",
                      backgroundImage:
                        "url(" +
                        process.env.PUBLIC_URL +
                        "/img/social/line.png",
                      backgroundSize: "22px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "5px",
                    }}
                  />
                </div>
                <div style={{ display: "table-cell" }}>
                  <div style={styles.socialContainer}>
                    <Icon>language</Icon>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
