import React, { useEffect, useState } from "react";
import Blank from "./Blank";
import Home from "./Home";
import Ingredient from "./Ingredient";
import Gallery from "./Gallery";
import Navbar from "./components/Navbar";
import About from "./About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";

function App() {
  const [config, setConfig] = useState();
  const pages = [
    {
      path: "/blank",
      component: Blank,
    },
    {
      path: "/",
      component: Home,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/ingredient/:id",
      component: Ingredient,
    },
    {
      path: "/gallery",
      component: Gallery,
    },

    /*
    {
      component: Login,
      path: "/",
    },
    {
      label: "ข้อมูล",
      menuId: 1,
      priority: 1,
    },
    {
      label: "บุคลากร",
      menuId: 10,
      priority: 1,
      subOf: 1,
      icon: "person",
      component: Person,
      path: "/person",
    },
    */
  ];
  useEffect(() => {
    (async () => {
      let config = await axios.get(process.env.PUBLIC_URL + "/config.json");
      setConfig(config.data);
    })();
  }, []);
  return (
    <div className="App">
      {config && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            {pages
              .filter((j) => j.component)
              .map((page, key) => (
                <Route
                  key={key}
                  path={`${process.env.PUBLIC_URL}${page.path}`}
                  element={<page.component page={page} />}
                />
              ))}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
