import React from "react";
import Container from "@mui/material/Container";

export default function Navbar() {
  return (
    <div>
      <Container maxWidth="xl">
        <div style={{ textAlign: "left", paddingTop: "10px" }}>
          <img
            src={process.env.PUBLIC_URL + "/img/headlogo.svg"}
            style={{ maxHeight: "70px", maxWidth: "80%" }}
            alt=""
          />
        </div>
      </Container>
    </div>
  );
}
