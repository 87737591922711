import React /*, { useRef, useState }*/ from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Box from "@mui/material/Box";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/resourceslider.css";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

export default function App() {
  const filenames = [
    "0001.jpeg",
    "0002.jpeg",
    "0003.jpeg",
    "0004.jpeg",
    "0005.jpeg",
    "0111.jpeg",
    "0112.jpeg",
  ];
  return (
    <>
      <div style={{ background: "#f8f1ef", padding: "40px 0px" }}>
        <div>
          <h1 style={{ margin: "0px", textAlign: "center" }} className="bold">
            Resources
          </h1>
        </div>
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          slidesPerGroup={5}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          className="resources-swiper-slide"
        >
          {filenames.map((slide, key) => (
            <SwiperSlide key={key}>
              <Box
                sx={{ boxShadow: 3 }}
                style={{
                  width: "150px",
                  height: "200px",
                  background: "#fff",
                  backgroundImage:
                    "url(" +
                    process.env.PUBLIC_URL +
                    "/img/cover/" +
                    slide +
                    ")",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  borderRadius: "8px 0px 0px 8px ",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px 0px 0px 8px ",
                    backgroundImage:
                      "linear-gradient(to right, rgba(100,100,100,.6) , rgba(255,0,0,0))",
                    borderRight: "1px solid rgba(255, 255, 255, .6)",
                    width: "7px",
                    height: "200px",
                  }}
                ></div>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
