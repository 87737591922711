import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import SliderGallery from "./components/SliderGallery";
import SliderIngredient from "./components/SliderIngredient";
import SliderResources from "./components/SliderResources";
import Footer from "./components/Footer";

import Button from "@mui/material/Button";

export default function Home() {
  const topMenu = [
    // { label: "Home" },
    { label: "About", url: process.env.PUBLIC_URL + "/about" },
    {
      label: "Resources",
      url: "https://services.library.cmu.ac.th/wenzhou/search",
    },
    // { label: "Herb" },
    // { label: "Gallery" },
  ];
  return (
    <div>
      <div
        style={{
          borderTop: "1px solid #fff",
          height: "650px",
          background: "url(" + process.env.PUBLIC_URL + "/img/heading/1.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Grid container spacing={1} justifyContent="flex-end">
          {topMenu.map((menu, key) => (
            <Grid
              item
              key={key}
              style={{
                padding: "30px 35px",
              }}
            >
              <a
                className="bold"
                href={menu.url || "#!"}
                style={{
                  color: "#fff",
                  fontSize: "1.2rem",
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noreferrer"
              >
                {menu.label}
              </a>
            </Grid>
          ))}
        </Grid>
        <Container maxWidth="md" style={{ textAlign: "left" }}>
          <form
            action="//cmu.on.worldcat.org/search"
            class="form-wc-search"
            target="_blank"
            method="get"
          >
            <div style={{ marginTop: "130px", color: "#eee" }}>
              <h1 style={{ marginBottom: "5px" }}>Worldwide Search</h1>
              <TextField
                fullWidth
                name="queryString"
                placeholder="Find library resources"
                InputProps={{
                  style: {
                    background: "#fff",
                    border: "2px solid #ccc",
                    fontSize: "1.3rem",
                    padding: "0px 0px",
                    borderRadius: "0px",
                  },
                }}
              />
            </div>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                startIcon={<Icon>search</Icon>}
                style={{ width: "180px" }}
              >
                Search
              </Button>
            </div>
          </form>
        </Container>
      </div>
      <SliderGallery />
      <SliderIngredient />
      <SliderResources />
      <Footer />
    </div>
  );
}
