import React from "react";
import Footer from "./components/Footer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function About() {
  const styles = {
    title: {
      color: "#111",
      fontWeight: 500,
      padding: "10px 0px",
      fontSize: "1.2rem",
    },
    paragraph: {
      color: "#111",
      textAlign: "justify",
      textIndent: "50px",
      paddingBottom: "10px",
      fontWeight: 300,
    },
  };
  return (
    <div style={{ borderTop: "2px solid #b21e23" }}>
      <Container style={{ padding: "40px 0px" }}>
        <CustomSeparator />
        <br />
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          style={{ fontWeight: 500, fontSize: "2rem" }}
        >
          Thai Traditional Medicine and Culture Center (TTMCC) Establishment
          Project
        </Typography>
        <br />

        <Typography component="div" style={styles.title}>
          1. Principle and Rationale
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          Thai Traditional Medicine and Culture Center (TTMCC) is as an academic
          collaboration between Chiang Mai University and Wenzhou Medical
          University, People's Republic of China, to promote academic
          cooperation in the areas of Traditional Chinese Medicine (TCM),
          Traditional Thai Medicine and to conduct cultural and personnel
          exchanges, and joint research. This collaboration offers a valuable
          opportunity to both universities for teaching, studying, and
          researching Thai traditional medicine and Chinese medicine, enabling
          Chiang Mai University to promote its international policy by building
          cooperation with foreign universities.
        </Typography>

        <Typography component="div" style={styles.title}>
          2. Project objectives
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          2.1 To establish the “Thai Traditional Medicine and Culture Center
          (TTMCC)” for exhibitions on Thai traditional medicine which reflects
          the Thai traditions, arts and culture, and wisdom which shall support
          the teaching and research on Thai traditional medicine.
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          2.2 To cooperate in conducting medical research in the fields agreed
          by both universities and to create a joint research network.
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          2.3 To cooperate in establishing the School of Traditional Medicine
          and cooperate academically in Thai Traditional Complementary Medicine
          (TTCM) to be a learning center for medical personnel to study and
          train on integrated medicine at both universities.
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          2.4 To support and strengthen academic cooperation between Chiang Mai
          University and Wenzhou Medical University.
        </Typography>
        <Typography component="div" style={styles.title}>
          3. Metrics of success
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          3.1 An exhibition at the Thai Traditional Medicine and Culture Center
          (TTMCC) with content consisting of (1) Printed books with information
          on Thai traditional medicine, Thai arts and culture, Thai wisdom,
          related Thai and local Lanna history, and a journal about Thai
          traditional medicine and information via the internet (2) Exhibits
          related to Thai traditional medicine (3) A computer for searching and
          using information on Thai traditional medicine, Chinese medicine, and
          related fields.
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          3.2 Cooperative research on Thai and Chinese traditional and modern
          medicine, Chinese herbs and Thai herbs by the Faculty of Medicine, and
          the Faculty of Pharmacy, CMU. In addition, research on topics of
          mutual interest maybe conducted in the future while jointly applying
          for international research grants to create a joint research network.
        </Typography>
        <Typography component="div" style={styles.paragraph}>
          3.3 Cooperation in establishing the School of Traditional Medicine and
          academic cooperation in Thai Traditional Complementary Medicine (TTCM)
          with the Center for Thai Traditional and Complementary Medicine,
          Faculty of Medicine, Chiang Mai University, who has the expertise in
          integrating Thai traditional medicine with modern medicine,
          acupuncture, and alternative treatments. It is a learning center for
          the medical personnel to study and train in integrated medicine. In
          the future, both sides will cooperate in organizing trainings held by
          the traditional Chinese medical personnel after the COVID-19 situation
          has been controlled. Also, the plan is to organize short-term
          trainings with a duration of 1-3 months, where Chiang Mai University
          will invite medical personnel from neighboring countries including
          Laos and Vietnam to train together, and will develop a joint course in
          herbal medicine.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
}

function CustomSeparator() {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={process.env.PUBLIC_URL + "/"}
      //   onClick={handleClick}
    >
      Home
    </Link>,
    <Typography key="3" color="text.primary">
      About
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
