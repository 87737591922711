import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { config } from "../const";
import axios from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/ingredientSlider.css";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

export default function SliderIngredient() {
  const [ingredients, setIngredients] = useState([]);
  const [ingredient, setIngredient] = useState();
  useEffect(() => {
    (async () => {
      let sql = "select * from ingredients";
      let ingredients = await axios.post(config.endpoint.ttmcc + "/index.php", {
        sql,
        limit: 0,
      });
      setIngredients(ingredients.data.result);
      setIngredient(ingredients.data.result[0]);
    })();
  }, []);
  return (
    <>
      {ingredient && (
        <Grid container spacing={0}>
          <Grid
            item
            xs={5}
            style={{
              background: "#d7d7d7",
              textAlign: "left",
              padding: "15px 40px",
            }}
          >
            <h2
              style={{
                marginBottom: "0px",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              {ingredient.title_alt}
            </h2>
            <h2 style={{ marginTop: "0px" }}>{ingredient.title}</h2>
            <p style={{ paddingRight: "100px", textAlign: "justify" }}>
              {ingredient.description_alt && ingredient.description_alt !== "-"
                ? ingredient.description_alt
                : ingredient.description}
            </p>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              background: "#b21e23",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div style={{ marginLeft: "-17%", marginRight: "50px" }}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={false}
                className="mySwiper"
                onSlideChange={(val) => {
                  if (val.activeIndex < ingredients.length) {
                    let id = "img_ing_" + ingredients[val.activeIndex]._id;
                    let Class = document
                      .getElementById(id)
                      .getAttribute("class");
                    Class += " selected-ingredient";
                    document.getElementById(id).setAttribute("class", Class);

                    id = "img_ing_" + ingredients[val.previousIndex]._id;

                    Class = Class.replaceAll("selected-ingredient", "");

                    document.getElementById(id).setAttribute("class", Class);
                    setIngredient(ingredients[val.activeIndex]);
                  }
                }}
                onReachEnd={() => {
                  console.log("bbb");
                }}
              >
                {ingredients.map((slide, key) => (
                  <SwiperSlide key={key}>
                    <div
                      href="#!"
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "5px solid #fff",
                        background: "#ccc",
                        borderRadius: "50%",
                        backgroundImage:
                          "url(" +
                          process.env.PUBLIC_URL +
                          "/img/herbs/" +
                          slide.filename +
                          ")",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                      id={"img_ing_" + slide._id}
                      onClick={() =>
                        window.open(
                          process.env.PUBLIC_URL + "/ingredient/" + slide._id
                        )
                      }
                    ></div>
                  </SwiperSlide>
                ))}

                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
              </Swiper>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
